
<template>
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <div class="heading">
      <h1 v-if="!buildingId">Add Building</h1>
      <h1 v-if="buildingId">Edit Building</h1></div>
  </el-col>
  <el-col :xs="6" :sm="6" :md="6" :lg="12" :xl="12">
    <div class="topsearch">
      <el-button type="primary" @click="buildings()">Back On Buildings</el-button>
    </div>
  </el-col>
 </el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
  <div v-if="showAlertAdded">
    <el-alert
      title="Building Added Successfully"
      type="success"
      effect="dark"
      v-if="!buildingId"
    />
    <el-alert
        title="Building Updated Successfully"
        type="success"
        effect="dark"
        v-if="buildingId"
    />
  </div>
  <div v-if="showAlertSlugexit">
    <el-alert
      title="The slug has already been taken."
      type="error"
      effect="dark"
    />
  </div>
  </el-col>
</el-row>

    <el-form
      ref="ruleForm"
      :model="data"
      status-icon
      :rules="rules"
      label-width="180px"
      class=" login-form"
      :label-position="labelPosition" 
    >
     <el-tabs type="border-card">
      <el-tab-pane label="Main Information"> 
      <div class="panel panel-default">
	<el-row :gutter="20">
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
		label="Building Category"
		prop="category"
		>
		<el-select v-model="data.category_name">
		<el-option 
		v-for="item in buildingcat" 
		:key="item.label"
		:value="item.value"
		:label="item.label"
		>
		{{ item.label }}
		</el-option>
		</el-select>
		</el-form-item></el-col>
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
		<el-form-item
		label="Building Name"
		prop="name"
		>
		<el-input
		v-model="data.name"
		type="text"
		autocomplete="off"
		/>
		</el-form-item>
		</el-col>
	</el-row>
	<el-row :gutter="20">
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
		label="Street Number"
		prop="street_number"
		>
		<el-input
		v-model="data.street_number"
		type="text"
		autocomplete="off"
		/>
		</el-form-item></el-col>
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
		<el-form-item
		label="Street Name"
		prop="street_name"
		>
		<el-input
		v-model="data.street_name"
		type="text"
		autocomplete="off"
		/>
		</el-form-item>
		</el-col>
	</el-row>
	<el-row :gutter="20">
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
		label="Street Type"
		prop="street_type"
		>
		<el-input
		v-model="data.street_type"
		type="text"
		autocomplete="off"
		/>
		</el-form-item></el-col>
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
		<el-form-item
		label="Street Direction"
		prop="street_dir"
		>
		<el-input
		v-model="data.street_dir"
		type="text"
		autocomplete="off"
		/>
		</el-form-item>
		</el-col>
	</el-row>
	<el-row :gutter="20">
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
		label="Country"
		prop="country"
		>
		<el-select v-model="data.country_name" placeholder="Select Country">
			<el-option
			v-for="item in countries"
			:key="item.value"
			:value="item.value"
			:label="item.label"
			@click="getStates(item.value)"
			></el-option>
		</el-select>
		</el-form-item></el-col>
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
		<el-form-item
		label="Area"
		prop="state"
		>
		<el-select v-model="data.state_name"  placeholder="Select Region / State">
		<el-option              
		v-for="item in states"
		:key="item.value"
		:label="item.label"
		:value="item.value"
		@click="getCities(item.value)"
		/>
		</el-select>
		</el-form-item>
		</el-col>
	</el-row>
	<el-row :gutter="20">
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
		label="Sub Area"
		prop="city"
		>
		<el-select v-model="data.city_name" placeholder="City">
		<el-option              
		v-for="item in cities"
		:key="item.value"
		:label="item.label"
		:value="item.value"
		/>
		</el-select>
		</el-form-item></el-col>
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
		<el-form-item
		label="Postal/Zip Code"
		prop="postalcode"
		>
		<el-input
		v-model="data.postalcode"
		type="text"
		autocomplete="off"
		/>
		</el-form-item>
		</el-col>
	</el-row>
	<el-row :gutter="20">
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
		label="Complex"
		prop="complex"
		>
		<el-select v-model="data.complex_name" placeholder="Select Complex">
		<el-option
		v-for="item in complexes"
		:key="item.value"
		:value="item.value"
		:label="item.label"
		></el-option>
		</el-select>
		</el-form-item></el-col>
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
		<el-form-item
		label="Maintenance Fee"
		prop="maintenance_fee"
		>
		<el-input
		v-model="data.maintenance_fee"
		type="text"
		autocomplete="off"
		/>
		</el-form-item>
		</el-col>
	</el-row>
</div>
 </el-tab-pane>
 <el-tab-pane label="Technical Info"> 
      <div class="panel panel-default">
      <el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
	label="Number of Suites"
	prop="suites"
	>
	<el-input-number
    controls-position="right"
	v-model="data.suites"
	/>
	</el-form-item></el-col>
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Number of Lots"
	prop="lots"
	>
	<el-input-number
    controls-position="right"
	v-model="data.lots"
	/>
	</el-form-item>
	</el-col>
	</el-row>
      <el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
	label="Number of Suites in Strata"
	prop="strata_suites"
	>
	<el-input-number
    controls-position="right"
	v-model="data.strata_suites"
	/>
	</el-form-item></el-col>
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Number of levels"
	prop="levels"
	>
	<el-input-number
    controls-position="right"
	v-model="data.levels"
	/>
	</el-form-item>
	</el-col>
	</el-row>
      <el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
	label="Year Built"
	prop="year_built"
	>
	<el-input-number
    controls-position="right"
	v-model="data.year_built"
	/>
	</el-form-item></el-col>
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Completion Status"
	prop="completion_status"
	>
	<el-select v-model="data.completion_status">
	<el-option 
	v-for="item in selectcompletion_status" 
	:key="item.label"
	:value="item.value"
	:label="item.label"
	>
	{{ item.label }}
	</el-option>
	</el-select>
	</el-form-item>
	</el-col>
	</el-row>
 <el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
	label="Completion Date"
	prop="completion_date"
	>
	<el-date-picker v-model="data.completion_date" type="date" placeholder="Pick a day">
	</el-date-picker>
	
	</el-form-item></el-col>
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Completion Comments"
	prop="completion_comments"
	>
	<el-input
	v-model="data.completion_comments"
	type="textarea"
	/>
	</el-form-item>
	</el-col>
	</el-row>
	<el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Type of Construction"
	prop="construction_type"
	>
	<el-select v-model="data.construction_type">
	<el-option 
	v-for="item in selectconstruction_type" 
	:key="item.label"
	:value="item.value"
	:label="item.label"
	>
	{{ item.label }}
	</el-option>
	</el-select>
	</el-form-item>
	</el-col>
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Property Type"
	prop="property_type"
	>
	<el-select v-model="data.property_type"	>
	<el-option 
	v-for="item in selectproperty_type" 
	:key="item.label"
	:value="item.value"
	:label="item.label"
	>
	{{ item.label }}
	</el-option>
	</el-select>
	</el-form-item>
	</el-col>
	</el-row>
	<el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
	label="Strata Plan Number"
	prop="strata_plan_no"
	>
	<el-input
	v-model="data.strata_plan_no"
	type="text"
	autocomplete="off"
	/>
	</el-form-item></el-col>
	
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
	label="Strata Image"
	prop="strata_image"
	>
	<el-upload
	class="upload-demo"
	drag
	:action="imageUploadUrl4"
	list-type="picture"
	:headers="imageUploadHeaders4"
	name="file"
	:file-list="fileList4"
	:limit="1"
	:on-preview="handlePreview4"
	:on-remove="handleRemove4"
	:on-success="handleImageUploadSuccess4"
	:on-error="handleImageUploadError4"
>
	<i class="el-icon-upload" />
	<div class="el-upload__text">
	Drop file here or <em>click to upload</em><br/> JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
	</div>
</el-upload>
	</el-form-item></el-col>
	</el-row>
	<el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Strata Website"
	prop="strata_website"
	>
	<el-input
	v-model="data.strata_website"
	type="text"
	autocomplete="off"
	/>
	</el-form-item>
	</el-col>
	
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
	label="Youtube Url"
	prop="youtube_url"
	>
	<el-input
	v-model="data.youtube_url"
	type="text"
	autocomplete="off"
	/>
	</el-form-item></el-col>
	</el-row>
	<el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Additional Info"
	prop="additional_info"
	>
	<el-input
	v-model="data.additional_info"
	type="textarea"
	/>
	</el-form-item>
	</el-col>
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Display Video Thumb"
	prop="videothumb"
	>
	<div class="align-left">
	<el-checkbox 
                  v-model="data.videothumb"
                /></div>
	</el-form-item>
	</el-col>
	</el-row>
</div>
 </el-tab-pane>
<el-tab-pane label="Amenities/Features">
	<div class="panel panel-default">
		<el-row :gutter="20">
			<el-col :xs="48" :sm="48" :md="48" :lg="48" :xl="48">
			<el-form-item prop="amenities">
				<el-checkbox-group v-model="data.amenities">
				<el-checkbox              
				v-for="amenity in arrayamenities"
				:key="amenity.value"
				:label="amenity.value"
				:value="amenity.value"
				>
				{{amenity.label}}
				</el-checkbox>
				</el-checkbox-group>
				</el-form-item> 
			</el-col>
			</el-row>
	</div>
</el-tab-pane>
  <el-tab-pane label="Description"> 
      <div class="panel panel-default">
	<el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Building Description"
	prop="description"
	>
	<el-input
	v-model="data.description"
	type="textarea"
	/>
	</el-form-item>
	</el-col>
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
	label="Realtor Building Description"
	prop="custom_building_description"
	>
	<el-input
	v-model="data.custom_building_description"
	type="textarea"
	/>
	</el-form-item></el-col>
	</el-row>
</div>
 </el-tab-pane>
  <el-tab-pane label="Restrictions"> 
	<div class="panel panel-default align-left">
	<el-form-item 
	label="Pets" 
	prop="pets_allowed"
	>
	<el-radio v-model="data.pets_allowed" label="Yes">Yes</el-radio>
	<el-radio v-model="data.pets_allowed" label="No">No</el-radio>
	</el-form-item>
</div>
 </el-tab-pane>
  <el-tab-pane label="Images"> 
      <div class="panel panel-default">
      <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item 
        label="Building Logo Image"
        prop="logo_image"
      ><el-upload
            class="upload-demo"
            drag
            :action="imageUploadUrl"
            list-type="picture"
            :headers="imageUploadHeaders"
            name="file"
            :file-list="fileList"
            :limit="1"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleImageUploadSuccess"
            :on-error="handleImageUploadError"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Drop file here or <em>click to upload</em><br>
          JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M</div>
           </el-upload>
      </el-form-item>
      </el-col>
</el-row> 
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item 
        label="Realtor Building Logo Image"
        prop="custom_logo_image"
      ><el-upload
            class="upload-demo"
            drag
            :action="imageUploadUrl_2"
            list-type="picture"
            :headers="imageUploadHeaders_2"
            name="file"
            :file-list="fileList2"
            :limit="1"
            :on-preview="handlePreview_2"
            :on-remove="handleRemove_2"
            :on-success="handleImageUploadSuccess_2"
            :on-error="handleImageUploadError_2"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Drop file here or <em>click to upload</em><br>
          JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M</div>
           </el-upload>
      </el-form-item>
      </el-col>
</el-row> 
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
    <el-form-item 
            label="More Images" 
            prop="more_image"
          >
            <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="fileListMoreImages"
              :limit="20"
              multiple
              :on-preview="handlePreviewMoreImages"
              :on-remove="handleRemoveMoreImages"
              :on-success="handleImageUploadSuccessMoreImages"
              :on-error="handleImageUploadErrorMoreImages"
			  id="moreimg-layout"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em><br/>
				JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
              </div>
            </el-upload>
          </el-form-item>
      </el-col>
</el-row> 
</div>
 </el-tab-pane>

 <el-tab-pane label="Map Location"> 
<div class="panel panel-default">
Map here!
</div>
 </el-tab-pane>
   <el-tab-pane label="Management Company"> 
   <div class="panel panel-default">
	<el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Management Company"
	prop="management_company"
	>
	<el-select v-model="data.management_company_name">
	<el-option 
	v-for="item in selectManagementCompany" 
	:key="item.label"
	:value="item.value"
	:label="item.label"
	>
	{{ item.label }}
	</el-option>
	</el-select>
	</el-form-item>
	</el-col>
	</el-row>
</div>

 </el-tab-pane>
   <el-tab-pane label="SEO Info"> 
      <div class="panel panel-default">
      <el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Meta Title"
	prop="meta_title"
	>
	<el-input
	v-model="data.meta_title"
	type="text"
	/>
	</el-form-item>
	</el-col>
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
	label="Meta Description"
	prop="meta_description"
	>
	<el-input
	v-model="data.meta_description"
	type="textarea"
	/>
	</el-form-item></el-col>
	</el-row>
	<el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Meta Keywords"
	prop="meta_keyword"
	>
	<el-input
	v-model="data.meta_keyword"
	type="text"
	autocomplete="off"
	/>
	</el-form-item>
	</el-col>
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
	label="Slug"
	prop="building_slug"
	>
	<el-input
	v-model="data.building_slug"
	type="text"
	autocomplete="off"
	/>
	</el-form-item></el-col>
</el-row>
</div>
 </el-tab-pane>
   <el-tab-pane label="Listings"> 
      <div class="panel panel-default">
	<el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
	<el-form-item
	label="Listings"
	prop="listing_ids"
	>
	<select multiple v-model="data.listing_ids" placeholder="Select Listings" class="selectmultiple2">
	<option
	v-for="item in listings"
	:key="item.value"
	:value="item.value"
	:label="item.label"
	></option>
	</select>

	</el-form-item>
	</el-col>
	</el-row>
</div>
 </el-tab-pane>
<el-tab-pane label="Building Website"> 
<div class="panel panel-default">
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="Building Website URL"
prop="website_url"
>
<el-input
v-model="data.website_url"
type="text"
/>
</el-form-item>
</el-col>
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
label="Custom Building Website URL"
prop="website_custom_url"
>
<el-input
v-model="data.website_custom_url"
type="text"
/>
</el-form-item></el-col>
</el-row>
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="Template"
prop="website_template"
>
<el-select v-model="data.website_template">
<el-option 
v-for="item in selectbuilding_template" 
:key="item.label"
:value="item.value"
:label="item.label"
>
{{ item.label }}
</el-option>
</el-select>
</el-form-item>
</el-col>
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
label="Custom Email"
prop="website_custom_email"
>
<el-input
v-model="data.website_custom_email"
type="text"
/>
</el-form-item></el-col>
</el-row>
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="Custom Phone"
prop="website_custom_phone"
>
<el-input
v-model="data.website_custom_phone"
type="text"
/>
</el-form-item>
</el-col>
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
label="Slug"
prop="single_property_slug"
>
<el-input
v-model="data.single_property_slug"
type="text"
/>
</el-form-item></el-col>
</el-row>
<el-row :gutter="20">

</el-row>
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="Building Website Logo"
prop="property_website_logo"
>
<el-upload
	class="upload-demo"
	drag
	:action="imageUploadUrl3"
	list-type="picture"
	:headers="imageUploadHeaders3"
	name="file"
	:file-list="fileList3"
	:limit="1"
	:on-preview="handlePreview3"
	:on-remove="handleRemove3"
	:on-success="handleImageUploadSuccess3"
	:on-error="handleImageUploadError3"
>
	<i class="el-icon-upload" />
	<div class="el-upload__text">
	Drop file here or <em>click to upload</em><br/>
	JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
	</div>
</el-upload>
</el-form-item>
</el-col>
</el-row>
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="Home Slider Images"
prop="home_slider_images"
>
<el-input
v-model="data.home_slider_images"
type="text"
/>
</el-form-item>
</el-col>
</el-row>
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="Building Background Images"
prop="background_images"
>
<el-input
v-model="data.background_images"
type="text"
/>
</el-form-item>
</el-col>
</el-row>
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
label="Building Website Logo"
prop="property_websitelogo_width"
>
<el-input
v-model="data.property_websitelogo_width"
placeholder="Width"
type="text"
/>
</el-form-item></el-col>
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="&nbsp;"
prop="property_websitelogo_height"
>
<el-input
v-model="data.property_websitelogo_height"
placeholder="Height"
type="text"
/>
</el-form-item>
</el-col>
</el-row>
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="Building Website Slideshow"
prop="property_websiteslideshow_width"
>
<el-input
v-model="data.property_websiteslideshow_width"
placeholder="Width"
type="text"
/>
</el-form-item>
</el-col>
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
label="&nbsp;"
prop="property_websiteslideshow_height"
>
<el-input
v-model="data.property_websiteslideshow_height"
placeholder="Height"
type="text"
/>
</el-form-item></el-col>
</el-row>
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="Property website Popup"
prop="property_websitepopup_width"
>
<el-input
v-model="data.property_websitepopup_width"
placeholder="Width"
type="text"
/>
</el-form-item>
</el-col>
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
label="&nbsp;"
prop="property_websitepopup_height"
>
<el-input
v-model="data.property_websitepopup_height"
placeholder="Height"
type="text"
/>
</el-form-item></el-col>
</el-row>
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="Property Website Thumb"
prop="property_websitethumb_width"
>
<el-input
v-model="data.property_websitethumb_width"
placeholder="Width"
type="text"
/>
</el-form-item>
</el-col>
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
label="&nbsp;"
prop="property_websitethumb_height"
>
<el-input
v-model="data.property_websitethumb_height"
placeholder="Height"
type="text"
/>
</el-form-item></el-col>
</el-row>
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="Instagram Feed"
prop="config_instafeed"
>
<el-select v-model="data.config_instafeed">
<el-option 
v-for="item1 in selectconfig_instafeed" 
:key="item1.label"
:value="item1.value"
:label="item1.label"
>
{{ item1.label }}
</el-option>
</el-select>
</el-form-item>
</el-col>
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
label="Custom Building URL Status"
prop="website_status"
>
<el-select v-model="data.website_status">
<el-option 
v-for="item in selectwebsite_status" 
:key="item.label"
:value="item.value"
:label="item.label"
>
{{ item.label }}
</el-option>
</el-select>
</el-form-item></el-col>
</el-row>
<el-row :gutter="20">
<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
<el-form-item
label="Agent Title"
prop="agent_title"
>
<el-input
v-model="data.agent_title"
type="text"
/>
</el-form-item>
</el-col>
</el-row>
</div>
</el-tab-pane>
  <el-row :gutter="20">

  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <div class="align-left">
      <el-form-item>
        <input
          id="buildingId"
          v-model="buildingId"
          name="buildingId"
          type="hidden"
        >
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
        >
          Submit
        </el-button>
        <el-button @click="resetForm('ruleForm')">
          Reset
        </el-button>
      </el-form-item>
       </div>
  </el-col>
 
</el-row>
    </el-tabs>
</el-form>
</template>
<script>
import AuthService from "@/api/auth.service";
import BuildingService from "@/api/building.service";
import authHeader from '@/api/auth-header';
import CountryService from "@/api/country.service";
import ListingsService from '@/api/listings.service';
import ComplexService from '@/api/complex.service';

//import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';

  export default {
	/*components: {
		LMap,
		LTileLayer,
		LMarker
	},*/
    data() {
		/*return {
		url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		attribution:
			'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		zoom: 15,
		center: [51.505, -0.159],
		markerLatLng: [51.504, -0.159]
		};*/
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Building Name.'));
        } else {
          callback();
        }
      };
      var checkDescription = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Building Description.'));
        } else {
          callback();
        }
      };
      var checkSlug = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Slug.'));
        } else {
          callback();
        }
      };
      var checkMetatitle = (rule, value, callback) => {        
          callback();
      };
      return {
		listings:[],
		complexes:[],
		otherinformation:[],
		arrayamenities:[],
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        buildingId: this.$route.params.buildingId,
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
		imageUploadUrl3: process.env.VUE_APP_BASE_API,
        imageUploadHeaders3: null,
		imageUploadUrl4: process.env.VUE_APP_BASE_API,
        imageUploadHeaders4: null,
        imageUploadUrl_2: process.env.VUE_APP_BASE_API,
        imageUploadHeaders_2: null,
		countries: [],
		states:[],
        cities:[],
	selectstatus:[{
          value: 'Enabled',
          label: 'Enabled'
        }, {
          value: 'Disabled',
          label: 'Disabled'
        }],
	selectconfig_instafeed:[{
          value: 1,
          label: 'Enabled'
        }, {
          value: 0,
          label: 'Disabled'
        }],
	selectwebsite_status:[{
          value: 1,
          label: 'Enabled'
        }, {
          value: 0,
          label: 'Disabled'
        }],
	selectproperty_type:[{
          value: 'Under Construction',
          label: 'Under Construction'
        }, {
          value: 'Freehold',
          label: 'Freehold'
        }, {
          value: 'Freehold - Live/Work',
          label: 'Freehold - Live/Work'
        }, {
          value: 'Leasehold',
          label: 'Leasehold'
        }, {
          value: 'Co-op',
          label: 'Co-op'
        }
	],
	selectManagementCompany:[{
          value: '',
          label: 'Select Management Company'
        },{
          value: 1,
          label: 'test'
        }, {
          value: 2,
          label: 'test2'
        }],
	selectconstruction_type:[{
          value: 'Concrete',
          label: 'Concrete'
        }, {
          value: 'Wood',
          label: 'Wood'
        }],
	selectbuilding_template:[{
          value: 'atriumatthepier',
          label: 'atriumatthepier'
        }, {
          value: 'cascadeatthepier',
          label: 'cascadeatthepier'
        }, {
          value: 'guardiansteelframes',
          label: 'guardiansteelframes'
        }, {
          value: 'singlebuilding',
          label: 'singlebuilding'
        }, {
          value: 'template-5340',
          label: 'template-5340'
        }, {
          value: 'trophyatthepier',
          label: 'trophyatthepier'
        }],
	selectlisting_ids:[{
          value: '',
          label: 'Select Listings For building'
        },{
          value: 1,
          label: ' 72 956 Street 20'
        }, {
          value: 2,
          label: '62 728 Street 25'
        }, {
          value: 3,
          label: '56 658 Street 25'
        }],
	selectcompletion_status:[{
          value: '',
          label: 'Select Completion Status'
        }, {
          value: 'Estimated Completion',
          label: 'Estimated Completion'
        }, {
          value: 'Completed',
          label: 'Completed'
        }, {
          value: 'Proposed',
          label: 'Proposed'
        }, {
          value: 'Under Construction',
          label: 'Under Construction'
        }],
	buildingcat:[{
          value: 1,
          label: 'Building'
        }, {
          value: 2,
          label: 'Business'
        }, {
          value: 3,
          label: 'Park'
        }, {
          value: 4,
          label: 'Plaza'
        }, {
          value: 5,
          label: 'Attraction'
        }, {
          value: 6,
          label: 'Community Center'
        }],
        fileList: [],
		fileList2:[],
		fileList3:[],
		fileList4:[],
		fileListMoreImages:[],
        data: {
		listing_ids:[],
		building_group: null,
		category: null,
		logo_image: null,
		custom_logo_image:null,	
		name: null,
		street_number: null,
		street_name: null,
		street_type: null,
		street_dir: null,
		country: null,
		country_name: null,
        state_name:null,
		state:null,
        city_name:null,
		city: null,
		postalcode: null,
		complex: null,
		maintenance_fee: null,
		suites: null,
		lots: null,
		strata_suites: null,
		levels: null,
		year_built: null,
		completion_date: null,
		completion_status: null,
		construction_type: null,
		property_type: null,
		pets_allowed: 'No',
		num_pets: null,
		description: null,
		more_images: null,
		lat: null,
		lng: null,
		youtube_url: null,
		videothumb: null,
		map_zoom: null,
		strata_plan_no: null,
		strata_website: null,
		strata_image: null,
		amenities: [],
		management_company: null,
		building_slug: null,
		added_by: null,
		completion_comments:null,
		additional_info:null,
		custom_building_description:null,
		meta_title:null,
		meta_description:null,
		meta_keyword:null,
		website_url:null,
		website_custom_url:null,
		website_template:null,
		website_custom_email:null,
		website_custom_phone:null,
		single_property_slug:null,
		agent_title:null,
		property_website_logo:null,
		home_slider_images:null,
		background_images:null,
		property_websitelogo_width:null,
		property_websitelogo_height:null,
		property_websiteslideshow_width:null,
		property_websiteslideshow_height:null,
		property_websitepopup_width:null,
		property_websitepopup_height:null,
		config_instafeed:null,
		website_status:null,


        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          name: [
            { validator: checkName, trigger: 'blur' }
          ],
          description: [
            { validator: checkDescription, trigger: 'blur' }
          ],
         
          building_slug: [
            { validator: checkSlug, trigger: 'blur' }
          ]
        }
      };
    },
    watch: {
		data: function () {
			if(!this.data.country_name && this.data.country) {
				this.data.country_name = this.extractNameById(this.data.country, this.countries);
			}
			if(!this.data.state_name && this.data.state) {
				this.data.state_name = this.extractNameById(this.data.state, this.states);
			}
			if(!this.data.city_name && this.data.city) {
				this.data.city_name = this.extractNameById(this.data.city, this.cities);
			}

			if(!this.data.complex_name && this.data.id) {
				this.data.complex_name = this.extractNameById(this.data.complex, this.complexes);
			}
			if(!this.data.category_name && this.data.category) {
				this.data.category_name = this.extractNameById(this.data.category, this.buildingcat);
			}
			if(!this.data.management_company_name && this.data.management_company) {
				this.data.management_company_name = this.extractNameById(this.data.management_company, this.selectManagementCompany);
			}
			
			
		},
		countries: function () {
		this.data.country_name = this.extractNameById(this.data.country, this.countries);
		}
		,states: function () {
		this.data.state_name = this.extractNameById(this.data.state, this.states);
		},
		cities: function () {
		this.data.city_name = this.extractNameById(this.data.city, this.cities);
		},
		complexes: function () {
		this.data.complex_name = this.extractNameById(this.data.complex, this.complexes);
		},
      
    },
	mounted() {
	this.getcountries();
    this.setImageUploadUrl();
	this.setImageUploadUrl2();
	this.setImageUploadUrl3();
	this.setImageUploadUrl4();
	this.getlistinglist();
	this.getcomplexlist();
	this.getAmenities();
    if(this.buildingId){
      this.getbuilding();
    }
  },
    methods: {
	async getCities(statsid){
		return CountryService.getCities(1,statsid).then(response => {
		this.cities=response.data.data;
		});
	},
	async getStates(countryid){
	return CountryService.getStates(1,countryid).then(response => {
		this.states=response.data.data;
	});
	},
	async getcountries(){
	return CountryService.getcountries().then(response => {
		this.countries=response.data.data;
	});
	},
	async getlistinglist(){
	return ListingsService.getlistinglist().then(response => {
	this.listings=response.data.data;
	});
	},
	async getcomplexlist(){
	return ComplexService.getcomplexlist().then(response => {
	this.complexes=response.data.data;
	});
	},
	async getAmenities(){
	return CountryService.getAmenities().then(response => {
	this.arrayamenities=response.data.data;
	});
	},
	extractNameById(needle, hayStack) {
	let val = null;
	val = hayStack.filter(function(element){
		if(element['value'] == Number(needle)) {
		return element['label'];
		}
	});
	if(val.length) {
		return val[0]['label'];
	}
	return null;
	},
      submitForm(formName) {
		this.data.country = this.data.country_name;
        this.data.state = this.data.state_name;
        this.data.city = this.data.city_name;   
		this.data.complex = this.data.complex_name;
		this.data.management_company = this.data.management_company_name;
		this.data.category = this.data.category_name;
		this.data.more_images=this.fileListMoreImages; 
        this.$refs[formName].validate((valid) => {
          if (valid && !this.buildingId) {
             return BuildingService.add(this.data).then(response => {
                // this.data = response.data
                this.showAlertAdded=true 
                return response
              });
          } else if (valid && this.buildingId) {
            return BuildingService.update(this.buildingId, this.data).then(response => {
				console.log(response);
              this.showAlertAdded=true;
              return response;

            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	buildings(){
		this.$router.push("/building");
	},
	getbuilding(){
    return BuildingService.get(this.buildingId).then(response => {
        this.data = response.data.data;
		if(this.data.country){
          this.getStates(this.data.country);
        }
        if(this.data.state){
          this.getCities(this.data.state);
        }   
		if(response.data.data.moreimages!=''){
          this.fileListMoreImages =response.data.data.moreimages;
        }
        this.fileList.push({name:'', url: response.data.data.image});
		this.fileList3.push({name:'', url: response.data.data.building_logo_img});
		this.fileList4.push({name:'', url: response.data.data.strata_image_view});
		this.fileList2.push({name:'', url: response.data.data.custom_logo});
        return response;
      });
	},
	setImageUploadUrl() {
	this.imageUploadHeaders = authHeader();
	this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
	},
	setImageUploadUrl4() {
	this.imageUploadHeaders4 = authHeader();
	this.imageUploadUrl4 = process.env.VUE_APP_BASE_API + 'image-upload';
	},
	setImageUploadUrl3() {
	this.imageUploadHeaders3 = authHeader();
	this.imageUploadUrl3 = process.env.VUE_APP_BASE_API + 'image-upload';
	},
	handleImageUploadSuccess(response, file, fileList) {
	if(response.code == 200) {
	this.data.logo_image = response.image_url;
	}
	},
	handleImageUploadSuccess3(response, file, fileList3) {
	if(response.code == 200) {
	this.data.property_website_logo = response.image_url;
	}
	},
	handleImageUploadSuccess4(response, file, fileList4) {
	if(response.code == 200) {
	this.data.strata_image = response.image_url;
	}
	},
	handleImageUploadError(err, file, fileList) {
	console.log(err)
	},
	handleImageUploadError3(err, file, fileList3) {
	console.log(err)
	},
	handleImageUploadError4(err, file, fileList4) {
	console.log(err)
	},
	handlePreview(file) {
	console.log(file)
	//TODO: Any Preview stuff
	},
	handlePreview3(file) {
	console.log(file)
	//TODO: Any Preview stuff
	},
	handlePreview4(file) {
	console.log(file)
	//TODO: Any Preview stuff
	},
	handleRemove(file, fileList) {
	console.log(file, fileList)
	//TODO: Delete the file
	},
	handleRemove3(file, fileList3) {
	console.log(file, fileList3)
	//TODO: Delete the file
	},
	handleRemove4(file, fileList4) {
	console.log(file, fileList4)
	//TODO: Delete the file
	},
	setImageUploadUrl2() {
	this.imageUploadHeaders_2 = authHeader();
	this.imageUploadUrl_2 = process.env.VUE_APP_BASE_API + 'image-upload';
	},
	handleImageUploadSuccess_2(response, file, fileList2) {
	if(response.code == 200) {
	this.data.custom_logo_image = response.image_url;
	}
	},
	handleImageUploadError_2(err, file, fileList2) {
	console.log(err)
	},
	handlePreview_2(file) {
	console.log(file)
	//TODO: Any Preview stuff
	},
	handleRemove_2(file, fileList2) {
	console.log(file, fileList2)
	//TODO: Delete the file
	},
	handleImageUploadSuccessMoreImages(response) {
        if(response.code == 200) {
			this.fileListMoreImages.push(response.image_url);
        }
      },
      handleImageUploadErrorMoreImages(err) {
        console.log(err)
      },
      handlePreviewMoreImages(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemoveMoreImages(file, fileListMoreImages) {
        console.log(file, fileListMoreImages)
        //TODO: Delete the file
      },
    }
  }
</script>
<style lang="scss" scoped>
.panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.el-row {
    margin-bottom: 0px !important;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
.el-select{
     width: 100%;
}
.el-input-number{width: 100%;}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}.align-left{text-align:left;}
.el-checkbox {    width: 25%;
    display: inline-block;
    float: left;margin-right:0px;}
 .selectmultiple2{-webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 80px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;}
</style>
<style lang="scss" >
.el-checkbox__input.is-checked + .el-checkbox__label{color:#606266 !important;}
.el-date-editor.el-input, .el-date-editor.el-input__inner{width:100%}
.el-checkbox {
    width: 25% !important;
    text-align: left;
}
.display-none{display:none;}
.row-border-top{border-top: 1px solid #E6E9ED;}
.padding-t-b-20{padding: 20px 0px 20px 0px;}
.line-height-5 .el-form-item__label{line-height: 5px;}
.append-col-name{font-size: var(--el-form-label-font-size);color: var(--el-text-color-regular);font-weight: 700;}
#moreimg-layout ul{display: inline-flex;gap: 20px;flex-wrap: wrap;}
#moreimg-layout ul li{width:200px;}
#moreimg-layout .el-upload-dragger{width:418px;}
.upload-demo{ display: flex;gap: 20px;width: 100%;max-width:100%;}
@media screen and (max-width: 992px) {
#moreimg-layout.upload-demo{ display: unset; gap: unset;width: unset;max-width:unset;}
}
@media screen and (max-width: 767px) {
.upload-demo{ display: unset; gap: unset;width: unset;max-width:unset;}
}
</style>
